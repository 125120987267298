﻿.blog__post {

	.blog__post-header {
		.blog__post-metadata {
			li {
				margin-right: 10px;

				.glyphicon-tags {
					margin-right: 2px;
				}
			}
		}
	}

	.blog__post-content {
		font-family: $font-family-serif;
		font-size: 20px;
		margin: 20px 0 50px;

		.blog__post-list & {
			margin: 0;
		}

		h1, h2, h3, h4, h5, h6 {
			font-family: $font-family-sans-serif;
		}

		> p, blockquote, pre, h1, h2, h3, h4, h5, h6, ul, ol, figure {
			margin-bottom: 30px;
		}

		p {
			&.lead {
				font-size: 25px;
			}
		}

		figure {

			figcaption {
				font-style: italic;
				text-align: center;
				margin: 10px 0 40px;
				font-size: 16px;
			}

			margin-left: -15px;
			margin-right: -15px;
		}
	}

	.blog__post-footer {
	}
}

.blog__profile {
	img.pull-right {
		padding: 0 0 10px 10px;
	}
}