﻿html, body {
	height: 100%;
}

.wrapper {
	display: table;
	height: 100%;
	width: 100%;
}

#content {
	display: table-row;
	/* height is dynamic, and will expand... */
	height: 100%;

	.container-fluid:last-of-type {
		margin-bottom : 20px;
	}
}

.footer {
	display: table-row;
}