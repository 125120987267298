﻿/* http://jovey-zheng.github.io/loader/ */

$loader-color: #333;
$loader-hand-width: 2px;
$loader-size: 24px;

[data-loader='timer'] {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -(($loader-size / 2) + $loader-hand-width);
	margin-left: -(($loader-size / 2) + $loader-hand-width);
	box-sizing: content-box;
	width: $loader-size;
	height: $loader-size;
	border: $loader-hand-width solid $loader-color;
	border-radius: 50%;
	background-color: transparent;
}

[data-loader='timer']:after,
[data-loader='timer']:before {
	position: absolute;
	/*
	top: 11px;
	left: 11px;
	*/
	top: 50%;
	left: 50%;
	margin-top: -1px;
	content: '';
	background-color: $loader-color;
}

[data-loader='timer']:after {
	width: 10px;
	height: $loader-hand-width;
	transform-origin: 1px 1px;
	animation: timerhand 2s linear infinite;
}

[data-loader='timer']:before {
	width: 8px;
	height: $loader-hand-width;
	transform-origin: 1px 1px;
	animation: timerhand 8s linear infinite;
}

@keyframes timerhand {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}
