﻿$image-spacing: 10px !default;

.galleries {
	.row {
		margin-left: -($image-spacing / 2);
		margin-right: -($image-spacing / 2);

		.col-xs-12, .col-sm-6, .col-md-3 {
			padding-left: ($image-spacing / 2);
			padding-right: ($image-spacing / 2);
		}
	}

	.thumbnail {
		padding: 0;
		margin-bottom: $image-spacing;
		border: none;
		position : relative;

		.thumbnail-detail {
			position : absolute;
			top : 0;
			right : 0;
			z-index : 1;
			padding : 2px;
			background-color : rgba(255,255,255,0.7);
		}


		a {
			position: relative;
			overflow: hidden;
			display: block;

			.caption {
				background-color: rgba(0,0,0,0.4);
				position: absolute;
				color: #fff;
				z-index: 100;
				transition: all 300ms ease-out;
				left: 0;
				width: 100%;
				display: block;
				padding: 9px 9px 0;

				h3 {
					margin-top: 0;
				}
			}

			&:hover, .touchevents & {
				.caption {
					transform: translateY(-100%);
				}
			}
		}
	}
}
