﻿.carousel {
	.carousel-control {
		&.left, &.right {
			background-image: none;
		}
	}

	&.jumbotron {
		padding-top: 0;
		padding-bottom: 0;

		.item {
			height: 500px;
		}
	}
}

/* Hero */

$jumbotron-hero-from-colour: #800000 !default;
$jumbotron-hero-to-colour: #000 !default;

.jumbotron {
	&.hero {
		background: url(/src/images/hero/Keyboard.jpg);
		position: relative;
		background-size: cover;
		background-position: center center;
		background-attachment: fixed;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(45deg, $jumbotron-hero-from-colour, $jumbotron-hero-to-colour);
			opacity: 0.8;
		}

		.container {
			position: relative;
		}

		&.hero-light {
			color: #fff;
		}
	}
}
