/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif: Lora, Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$navbar-border-radius: 0;

$bootstrap-sass-asset-helper: false;
$icon-font-path: "../fonts/";

//$breadcrumb-separator: "\25b6";

// Core variables and mixins
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";

body {
	padding-top: 50px;
}

a {
	transition: all 0.3s ease;
}

img {
	max-width : 100%;
}

@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
	white-space: normal;
}

dl {
	&.dl-horizontal-inline {
		dt {
			float: left;
			clear: left;
		}

		dd {
			float: left;
			margin-left: 5px;
		}
	}
}

.page-header {
	margin: 0;
	padding-bottom: 0;
	border-bottom: none;
}

@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
	max-width: 280px;
}

@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "./src/styles/components/navbar-branded";

.navbar-brand {
	font-weight: bold;
}

.footer {
	.navbar {
		margin-bottom: 0;

		.navbar-text {
			margin-bottom: 0;

			p {
				display: inline-block;
			}
		}
	}
}


/*
@media screen and (min-width: $screen-sm-min) {

	body {
		padding-top : 70px;
	}

	.wrapper-top {
		.navbar-brand {
			line-height : 70px;
		}

		.navbar-nav > li > a {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}
*/


$navbar-footer-link-color: transparentize($navbar-default-link-color, 0.1) !default;
$navbar-footer-link-hover-color: lighten($navbar-default-link-hover-color, 10%) !default;
$navbar-footer-link-hover-bg: lighten($navbar-default-link-hover-bg, 10%) !default;
$navbar-footer-link-active-color: lighten($navbar-default-link-active-color, 10%) !default;
$navbar-footer-link-active-bg: lighten($navbar-default-link-active-bg, 10%) !default;
$navbar-footer-link-disabled-color: lighten($navbar-default-link-disabled-color, 10%) !default;
$navbar-footer-link-disabled-bg: lighten($navbar-default-link-disabled-bg, 10%) !default;



$navbar-footer-color: $navbar-default-color !default;
$navbar-footer-bg: $navbar-default-bg !default;
$navbar-footer-border: $navbar-default-border !default;

// Navbar brand label
$navbar-footer-brand-color: $navbar-default-brand-color !default;
$navbar-footer-brand-hover-color: $navbar-default-brand-hover-color !default;
$navbar-footer-brand-hover-bg: $navbar-default-brand-hover-bg !default;

// Navbar toggle
$navbar-footer-toggle-hover-bg: $navbar-default-toggle-hover-bg !default;
$navbar-footer-toggle-icon-bar-bg: $navbar-default-toggle-icon-bar-bg !default;
$navbar-footer-toggle-border-color: $navbar-default-toggle-border-color !default;


.navbar-footer {
	background-color: $navbar-footer-bg;
	border-color: $navbar-footer-border;

	.navbar-brand {
		color: $navbar-footer-brand-color;

		&:hover,
		&:focus {
			color: $navbar-footer-brand-hover-color;
			background-color: $navbar-footer-brand-hover-bg;
		}
	}

	.navbar-text {
		color: $navbar-footer-color;
	}

	.navbar-nav {
		> li > a {
			color: $navbar-footer-link-color;

			&:hover,
			&:focus {
				color: $navbar-footer-link-hover-color;
				background-color: $navbar-footer-link-hover-bg;
			}
		}

		> .active > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-footer-link-active-color;
				background-color: $navbar-footer-link-active-bg;
			}
		}

		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-footer-link-disabled-color;
				background-color: $navbar-footer-link-disabled-bg;
			}
		}
	}
	// Darken the responsive nav toggle
	.navbar-toggle {
		border-color: $navbar-footer-toggle-border-color;

		&:hover,
		&:focus {
			background-color: $navbar-footer-toggle-hover-bg;
		}

		.icon-bar {
			background-color: $navbar-footer-toggle-icon-bar-bg;
		}
	}

	.navbar-collapse,
	.navbar-form {
		border-color: darken($navbar-footer-bg, 7%);
	}
	// Dropdowns
	.navbar-nav {
		> .open > a {
			&,
			&:hover,
			&:focus {
				background-color: $navbar-footer-link-active-bg;
				color: $navbar-footer-link-active-color;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
			// Dropdowns get custom display
			.open .dropdown-menu {
				> .dropdown-header {
					border-color: $navbar-footer-border;
				}

				.divider {
					background-color: $navbar-footer-border;
				}

				> li > a {
					color: $navbar-footer-link-color;

					&:hover,
					&:focus {
						color: $navbar-footer-link-hover-color;
						background-color: $navbar-footer-link-hover-bg;
					}
				}

				> .active > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-footer-link-active-color;
						background-color: $navbar-footer-link-active-bg;
					}
				}

				> .disabled > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-footer-link-disabled-color;
						background-color: $navbar-footer-link-disabled-bg;
					}
				}
			}
		}
	}

	.navbar-link {
		color: $navbar-footer-link-color;

		&:hover {
			color: $navbar-footer-link-hover-color;
		}
	}

	.btn-link {
		color: $navbar-footer-link-color;

		&:hover,
		&:focus {
			color: $navbar-footer-link-hover-color;
		}

		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
				color: $navbar-footer-link-disabled-color;
			}
		}
	}
}


@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";

.breadcrumb {
	border: 1px solid $table-border-color;
}


@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";

.thumbnail {
	border: none;
	padding: 0;
	border-radius: 0;
}

@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

.img-responsive {
	&.img-responsive-full {
		width: 100%;
	}
}

.color-brand-primary {
	color: $brand-primary;
}

.color-brand-success {
	color: $brand-success;
}

.color-brand-info {
	color: $brand-info;
}

.color-brand-warning {
	color: $brand-warning;
}

.color-brand-danger {
	color: $brand-danger;
}
