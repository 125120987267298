@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets';

// http://www.colourlovers.com/palette/4322238/colourful_houses

$colour-imnotanswering: #3e609f; // I'm Not Answering
$colour-mirroronthewall: #7f886d; // mirror on the wall
$colour-nosugar: #b93530; // no sugar
$colour-yellowish: #e9a63e; // yellowish
$colour-frenchvanilla: #f2d5b5; // french vanilla

$brand-primary: $colour-imnotanswering;
$brand-success: $colour-mirroronthewall;
$brand-info: $colour-frenchvanilla;
$brand-warning: $colour-yellowish;
$brand-danger: $colour-nosugar;

$headings-color: $colour-imnotanswering;

$jumbotron-hero-from-colour: $colour-imnotanswering;

@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import './src/styles/vendor/bootstrap-custom';

@import './src/styles/components/blog';
@import './src/styles/components/social-links';
@import './src/styles/components/photo-library';
//@import './src/styles/components/btn-breadcrumb';
@import './src/styles/components/sticky-footer';
//@import './src/styles/components/fullscreen-background';
@import './src/styles/components/alerts';
@import './src/styles/components/loader';
@import './src/styles/components/jumbotron';
