﻿// Brand navbar

//=== Brand navbar
// Reset brand navbar basics
$navbar-branded-color: lighten($gray-light, 15%) !default;
$navbar-branded-bg: #222 !default;
$navbar-branded-border: darken($navbar-branded-bg, 10%) !default;

// Brand navbar links
$navbar-branded-link-color: lighten($gray-light, 15%) !default;
$navbar-branded-link-hover-color: #fff !default;
$navbar-branded-link-hover-bg: transparent !default;
$navbar-branded-link-active-color: $navbar-branded-link-hover-color !default;
$navbar-branded-link-active-bg: darken($navbar-branded-bg, 10%) !default;
$navbar-branded-link-disabled-color: #444 !default;
$navbar-branded-link-disabled-bg: transparent !default;

// Brand navbar brand label
$navbar-branded-brand-color: $navbar-branded-link-color !default;
$navbar-branded-brand-hover-color: #fff !default;
$navbar-branded-brand-hover-bg: transparent !default;

// Brand navbar toggle
$navbar-branded-toggle-hover-bg: #333 !default;
$navbar-branded-toggle-icon-bar-bg: #fff !default;
$navbar-branded-toggle-border-color: #333 !default;

.navbar-branded {
	background-color: $navbar-branded-bg;
	border-color: $navbar-branded-border;

	.navbar-branded {
		color: $navbar-branded-brand-color;

		&:hover,
		&:focus {
			color: $navbar-branded-brand-hover-color;
			background-color: $navbar-branded-brand-hover-bg;
		}
	}

	.navbar-text {
		color: $navbar-branded-color;
	}

	.navbar-nav {
		> li > a {
			color: $navbar-branded-link-color;

			&:hover,
			&:focus {
				color: $navbar-branded-link-hover-color;
				background-color: $navbar-branded-link-hover-bg;
			}
		}

		> .active > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-branded-link-active-color;
				background-color: $navbar-branded-link-active-bg;
			}
		}

		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-branded-link-disabled-color;
				background-color: $navbar-branded-link-disabled-bg;
			}
		}
	}
	// Darken the responsive nav toggle
	.navbar-toggle {
		border-color: $navbar-branded-toggle-border-color;

		&:hover,
		&:focus {
			background-color: $navbar-branded-toggle-hover-bg;
		}

		.icon-bar {
			background-color: $navbar-branded-toggle-icon-bar-bg;
		}
	}

	.navbar-collapse,
	.navbar-form {
		border-color: darken($navbar-branded-bg, 7%);
	}
	// Dropdowns
	.navbar-nav {
		> .open > a {
			&,
			&:hover,
			&:focus {
				background-color: $navbar-branded-link-active-bg;
				color: $navbar-branded-link-active-color;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
			// Dropdowns get custom display
			.open .dropdown-menu {
				> .dropdown-header {
					border-color: $navbar-branded-border;
				}

				.divider {
					background-color: $navbar-branded-border;
				}

				> li > a {
					color: $navbar-branded-link-color;

					&:hover,
					&:focus {
						color: $navbar-branded-link-hover-color;
						background-color: $navbar-branded-link-hover-bg;
					}
				}

				> .active > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-branded-link-active-color;
						background-color: $navbar-branded-link-active-bg;
					}
				}

				> .disabled > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-branded-link-disabled-color;
						background-color: $navbar-branded-link-disabled-bg;
					}
				}
			}
		}
	}

	.navbar-link {
		color: $navbar-branded-link-color;

		&:hover {
			color: $navbar-branded-link-hover-color;
		}
	}

	.btn-link {
		color: $navbar-branded-link-color;

		&:hover,
		&:focus {
			color: $navbar-branded-link-hover-color;
		}

		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
				color: $navbar-branded-link-disabled-color;
			}
		}
	}
}




// Inverse branded navbar

//=== Inverted branded navbar
// Reset inverted navbar basics
$navbar-branded-inverse-color: lighten($gray-light, 15%) !default;
$navbar-branded-inverse-bg: #222 !default;
$navbar-branded-inverse-border: darken($navbar-branded-inverse-bg, 10%) !default;

// Inverted branded navbar links
$navbar-branded-inverse-link-color: lighten($gray-light, 15%) !default;
$navbar-branded-inverse-link-hover-color: #fff !default;
$navbar-branded-inverse-link-hover-bg: transparent !default;
$navbar-branded-inverse-link-active-color: $navbar-branded-inverse-link-hover-color !default;
$navbar-branded-inverse-link-active-bg: darken($navbar-branded-inverse-bg, 10%) !default;
$navbar-branded-inverse-link-disabled-color: #444 !default;
$navbar-branded-inverse-link-disabled-bg: transparent !default;

// Inverted branded branded navbar brand label
$navbar-branded-inverse-brand-color: $navbar-branded-inverse-link-color !default;
$navbar-branded-inverse-brand-hover-color: #fff !default;
$navbar-branded-inverse-brand-hover-bg: transparent !default;

// Inverted navbar toggle
$navbar-branded-inverse-toggle-hover-bg: #333 !default;
$navbar-branded-inverse-toggle-icon-bar-bg: #fff !default;
$navbar-branded-inverse-toggle-border-color: #333 !default;

.navbar-branded-inverse {
	background-color: $navbar-branded-inverse-bg;
	border-color: $navbar-branded-inverse-border;

	.navbar-brand {
		color: $navbar-branded-inverse-brand-color;

		&:hover,
		&:focus {
			color: $navbar-branded-inverse-brand-hover-color;
			background-color: $navbar-branded-inverse-brand-hover-bg;
		}
	}

	.navbar-text {
		color: $navbar-branded-inverse-color;
	}

	.navbar-nav {
		> li > a {
			color: $navbar-branded-inverse-link-color;

			&:hover,
			&:focus {
				color: $navbar-branded-inverse-link-hover-color;
				background-color: $navbar-branded-inverse-link-hover-bg;
			}
		}

		> .active > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-branded-inverse-link-active-color;
				background-color: $navbar-branded-inverse-link-active-bg;
			}
		}

		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-branded-inverse-link-disabled-color;
				background-color: $navbar-branded-inverse-link-disabled-bg;
			}
		}
	}
	// Darken the responsive nav toggle
	.navbar-toggle {
		border-color: $navbar-branded-inverse-toggle-border-color;

		&:hover,
		&:focus {
			background-color: $navbar-branded-inverse-toggle-hover-bg;
		}

		.icon-bar {
			background-color: $navbar-branded-inverse-toggle-icon-bar-bg;
		}
	}

	.navbar-collapse,
	.navbar-form {
		border-color: darken($navbar-branded-inverse-bg, 7%);
	}
	// Dropdowns
	.navbar-nav {
		> .open > a {
			&,
			&:hover,
			&:focus {
				background-color: $navbar-branded-inverse-link-active-bg;
				color: $navbar-branded-inverse-link-active-color;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
			// Dropdowns get custom display
			.open .dropdown-menu {
				> .dropdown-header {
					border-color: $navbar-branded-inverse-border;
				}

				.divider {
					background-color: $navbar-branded-inverse-border;
				}

				> li > a {
					color: $navbar-branded-inverse-link-color;

					&:hover,
					&:focus {
						color: $navbar-branded-inverse-link-hover-color;
						background-color: $navbar-branded-inverse-link-hover-bg;
					}
				}

				> .active > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-branded-inverse-link-active-color;
						background-color: $navbar-branded-inverse-link-active-bg;
					}
				}

				> .disabled > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-branded-inverse-link-disabled-color;
						background-color: $navbar-branded-inverse-link-disabled-bg;
					}
				}
			}
		}
	}

	.navbar-link {
		color: $navbar-branded-inverse-link-color;

		&:hover {
			color: $navbar-branded-inverse-link-hover-color;
		}
	}

	.btn-link {
		color: $navbar-branded-inverse-link-color;

		&:hover,
		&:focus {
			color: $navbar-branded-inverse-link-hover-color;
		}

		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
				color: $navbar-branded-inverse-link-disabled-color;
			}
		}
	}
}
