﻿.social-links {
	margin-left: 0;

	&.social-links--cv {
		.social-links__link {
			font-size: 130%;
		}
	}

	&.social-links--inverse-on-hover {
		a {
			&:hover {
				background-color: $link-hover-color;
				color: $body-bg;
				border-color: $link-hover-color;
			}
		}
	}

	&.social-links--footer {
		display: inline-block;
		float: right;
		margin: 0 0 0 5px;

		.social-links__link {
			a {
				border-color: $navbar-footer-link-color;

				&:hover {
					border-color: $navbar-footer-link-hover-color;
				}
			}

			.navbar-default & {
				a {
					border-color: $navbar-default-link-color;

					&:hover {
						border-color: $navbar-default-link-hover-color;
					}
				}
			}

			.navbar-inverse & {
				a {
					border-color: $navbar-inverse-link-color;

					&:hover {
						color: $navbar-inverse-link-hover-color;
						border-color: $navbar-inverse-link-hover-color;
					}
				}
			}
		}

		&.social-links--inverse-on-hover {
			.navbar-default & {
				a {
					&:hover {
						background-color: $navbar-footer-link-hover-color;
						color: $navbar-footer-bg;
						border-color: $navbar-footer-link-hover-color;
					}
				}
			}
		}

		&.social-links--inverse-on-hover {
			.navbar-inverse & {
				a {
					&:hover {
						background-color: $navbar-inverse-link-hover-color;
						color: $navbar-inverse-bg;
						border-color: $navbar-inverse-link-hover-color;
					}
				}
			}
		}
	}

	.social-links__link {
		padding-left: 0;
		padding-right: 0;

		a {
			border: 1px solid $link-color;
			border-radius: 6px;

			&:hover {
				border-color: $link-hover-color;
			}
		}
	}
}
